import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227jIBB9z1egSis1UolwmnRbV/2Q1aoPGMYOGwwW4Fy62n9fGV9CHJy2q+6bDcww58xwZhYsqe9lRdDvGUIbEMXGpWhNqsPzDCEubCXpMUW5BL9ApSgUFg5KmyIGyoFplgtapeixtako50IVKSLooV3JtOFgsKFc1DZFq3ZV104KBSlSWkGzsBfcbVKUEPKt+S3pAXdLa9LFw7TUJkU7am4xpnkuFGAHB4crI0pqjvPn2Z/ZokOUbvQOjMfFamMbw0qLPuSMsm1hdK34yJ+3wv6m0F3iHXUBkcZDw0mKkn/mSVvhhFYpMiCpEzsIblv62xpv2G6MUFt/57B977c7NBh2oJw9EakryoQ7pogsHkOjCCEcclpLNyak9TRYrs6KY7XsErgDk0u9T9FGcA4qMFh7gwtWPB4uDLAWONOyLtUFXy1sR42PyxmqbK5Nmbafkjr4cUvmw17H4nAOkcXaIlZnguEM3gSY28VqfbdYJnfkLpkjYkNoPzl1FFPWJODlxpkabl7RGYx4ALihISyQh7BA+iL2UArTkJTEObvk9RO0/aqtE/kRM60cKBeWWkhNVxBfR8xDu+mOFbzcKG1KKm9ePf5T7U37yqm0U87aWr7m7Hv7NLRy2Io3GL3edr301SH9c+7YXXbs+gP7Qef8S64tGGxBAnPBK4qkymvNaQOkFJUV1qvXRjjAtqLMS9re0CoiakNuhfLRZVKzbQDuMf5wvG50ACak5SohBxshhEQIWbWExHQ2k5Rt8ZrMz8K1u+Ks7jvJ7y/p/2MOBdMKW2Ba8ZF0P/0fFq6VxWRnGQK8bAlPX4o+ADyuCvp+yV/LcNCRu95TCjU6NdyVfVa6r2hQ0CGvNAs2/db7Hh4/mQQnedvWPkZ0wGSyOkMPJ8XvtJNKicgiubcIqA3nFBJmuqc6JholNYVQWELuOquPjgtXqK0kZXBpMUnAaUCamscy3cwalDfB9xoYzgSelIoaUL4tX4xUU0mD8IX0PIWXnatM7VyTL783vzqo9UdjCpJ3A9KQxcxqWTsPyrQpi2cinNCiWZmCOb4xMtQV42ecrN7RB98xqQPeYPsL07Ph/aoLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeContainer = 'c1u3lp2';
export var activeIcon = 'c1u3lpg';
export var collaborationIcon = 'c1u3lpd';
export var container = 'c1u3lp0';
export var disable = 'c1u3lp3';
export var enableCloudButton = 'c1u3lpc';
export var infoContainer = 'c1u3lp1';
export var settingButton = 'c1u3lpe';
export var showOnCardHover = 'c1u3lpf';
export var workspaceActiveStatus = 'c1u3lp9';
export var workspaceInfo = 'c1u3lp6';
export var workspaceInfoSlide = 'c1u3lp5';
export var workspaceInfoSlider = 'c1u3lp4';
export var workspaceInfoTooltip = 'c1u3lpa';
export var workspaceName = 'c1u3lp7';
export var workspaceStatus = 'c1u3lp8';
export var workspaceTitleContainer = 'c1u3lpb';